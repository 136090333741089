import * as React from "react";
import { ScrollIconSVG } from '../../Atoms/Icons/Icons'
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';


import './ScrollIcon.scss';


const ScrollIcon = () => {
  const intl = useIntl();
  const onClickScrollIcon = (e) => {
    e.preventDefault();
    const firstElement = document.querySelectorAll('.section_content')[0] || document.querySelector('.page_content');
    if (firstElement) {
      window.scrollTo({ top: firstElement.offsetTop, behavior: 'smooth' });
    } else {
      alert('Nothing to scroll');
    }
  }

  return (
    <a href="#" className="scroll_icon" role="button" onClick={(e) => onClickScrollIcon(e)} aria-label={intl.formatMessage({ id: 'ariaLabel.access_content_scroll' })}>
      <span>Scroll</span>
      <ScrollIconSVG color="color_white" aria-hidden="true" />
    </a>
  );
};


export default ScrollIcon;